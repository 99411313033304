<template>
	<div class="tab_box tab_box_white no-copy">
		<div class="bg_logo">
			<img v-if="routerKey === '7-2-1'" class="img2" src="@/assets/img/bg_title5.png" alt="" />
			<img v-else class="img1" src="@/assets/img/bg_title4.png" alt="" />
			<h6 :class="routerKey === '7-2-1' ? 'bg_logo_name1' : 'bg_logo_name'"></h6>
		</div>
		<el-menu class="el-menu-demo" mode="horizontal" @select="handleSelect" :default-active="'1'">
			<el-menu-item v-for="i in tabList" :index="i.idx" :key="i.index">{{
        i.title
      }}</el-menu-item>
		</el-menu>
		<div class="logo_en" v-if="false">En</div>
		<div v-if="!userName" class="logo-btn" @click="loginVisible">登录</div>
		<div v-else class="logo-name" title="退出">
			{{ hideUserName }}
			<div class="quit_btn" @click="quit">退出</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		routerKey: {
			type: String,
			default: "0",
		}
	},
	data() {
		return {
			tabList: [
				{
					idx: "0",
					path: "/index/index",
					title: "首页",
				},
				{
					idx: "1",
					path: "/joinType/match",
					title: "乐活通",
				},
        {
					idx: "2",
					path: "/mall/step/mall",
					title: "乐活秀",
				}
			],
		};
	},
	computed: {
		activeIndex() {
			return this.routerKey.split("-")[0];
		},
		userName() {
			return this.$store.state.userName;
		},
		hideUserName() {
			return this.userName.replace(/(\d{3})\d*(\d{2})/, "$1******$2");
		}
	},
	watch: {},
	mounted() {
  },
	methods: {
		// 导航点击
		handleSelect(key) {
      console.log(this.tabList[key].path)
			this.$router.push(this.tabList[key].path);
		},
		// 登陆弹框事件
		loginVisible() {
			this.$emit("loginVisible", true);
		},
		// 退出
		quit() {
			this.$confirm("确认退出吗?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					localStorage.clear();
					sessionStorage.clear();
					this.$router.push("mallGuide");
					this.$store.dispatch("quit");
				})
				.catch(() => {
					console.log("取消");
				});
		},
	},
};
</script>

<style lang="scss" scoped>
/* 禁止复制 */
.no-copy {
	-moz-user-select: none;
	/* Firefox私有属性 */
	-webkit-user-select: none;
	/* WebKit内核私有属性 */
	-ms-user-select: none;
	/* IE私有属性(IE10及以后) */
	-khtml-user-select: none;
	/* KHTML内核私有属性 */
	-o-user-select: none;
	/* Opera私有属性 */
	user-select: none;
	/* CSS3属性 */
}

.tab_box {
	min-width: 1280px;
	max-width: 2500px;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: relative;
	height: 80px;

  &.tab_box_white {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);

    .el-menu--horizontal > .el-menu-item.is-active {
      color: #333;

      &:after {
        background-color: #EE9118;
      }
    }
    .logo_en {
      border: 1px solid #666;
      color: #666;

      &:hover {
        border: 1px solid #333;
        color: #333;
      }
    }

    .logo-btn {
      color: #EE9118 !important;
      border: 1px solid #EE9118 !important;
      box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.05);

      &:hover {
        color: #EE9118;
        border: 1px solid rgba(238, 145, 24, 1);
        background-color: rgba(238, 145, 24, 0.1) !important;
      }
    }
  }
	.bg_logo {
		width: 207px;
		height: 55px;
		left: 40px; //媒体选择
		top: 12px;
		position: absolute;
		.img1 {
			float: left;
			width: 64px;
			height: auto;
			object-fit: contain;
			//position: absolute;
			top: 10%;
			left: 0;
		}
		.img2 {
			float: left;
			width: 64px;
			height: auto;
			object-fit: contain;
			//position: absolute;
			top: 10%;
			left: 0;
		}

		.bg_logo_name {
			float: right;
			font-size: 12px;
			color: white;
			margin-top: 20px;
		}
		.bg_logo_name1 {
			float: right;
			font-size: 12px;
			color: #000000;
			margin-top: 20px;
		}
	}
	.el-menu {
		border-bottom: 0;
		background: transparent;
	}
	.el-menu-item:not(.is-active):hover {
		// color: #ffffff;
		height: 80px;
		line-height: 80px;
		transform: scale(1.1);
	}
	.el-menu-item {
		// color: #ffffff;
		font-size: 18px;
		height: 80px;
		line-height: 80px;
		padding: 0 20px;
		font-weight: 400;
		transition: all 0.1s ease-in-out;
	}

	.el-menu--horizontal > .el-menu-item {
		border-bottom: 0;
		height: 80px;
		line-height: 80px;
	}

	.el-menu--horizontal > .el-menu-item.is-active {
		color: #31238e;
		border-bottom: 0;
		position: relative;
		font-weight: 700;
		font-size: 20px;
		&::after {
			content: "";
			width: 30px;
			height: 4px;
			background-color: #31238e;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
		}
	}

	.logo_en {
		margin-left: 18px;
		width: 14px;
		height: 14px;
		text-align: center;
		line-height: 14px;
		font-size: 10px;
		border-radius: 2px;
		border: 1px solid rgba(255, 255, 255, 0.45);
		color: rgba(255, 255, 255, 0.45);
		cursor: pointer;

		&:hover {
			border: 1px solid #fff;
			color: #fff;
		}
	}

	.logo-btn {
		width: 100px;
		height: 36px;
		line-height: 36px;
		text-align: center;
		font-size: 16px;
		border-radius: 50px;
		cursor: pointer;
		box-sizing: border-box;
		margin-left: 40px;
		margin-right: 20px;
		color: #fff !important;
		border: 1px solid #fff !important;
		box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.05);

		&:hover {
			color: #ffffff;
			border: 1px solid rgba(255, 255, 255, 1);
			background-color: rgba(255, 255, 255, 0.1) !important;
		}
	}
	.logo-name {
		line-height: 21px;
		cursor: pointer;
		margin-left: 36px;
		margin-right: 40px; //媒体选择
		display: flex;
		align-items: center;
		font-size: 16px;

		.quit_btn {
			margin-left: 10px;
			color: rgba(255, 255, 255, 0.5);

			&:hover {
				color: #fff;
			}
		}
	}
}
@media screen and (max-width: 1331px) {
	/*当屏幕尺寸小于1331px时，应用下面的CSS样式*/
	.tab_box .bg_logo {
		left: 10px;
	}
	.tab_box .logo-name {
		margin-right: 5px;
	}
}
</style>
